import content from "../config/content.json"

function fileToBase64(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = function() {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
}
function downloadFile(file) {
  const fileFormat = file.fileName.split(".").pop();
  const fileLink = document.createElement("a");
  fileLink.href = `data:application/${fileFormat};base64,${file.fileContent}`;
  fileLink.setAttribute("download", file.fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
}
// function getInputContent(pageName, role) {
//   if (content[0][pageName]) {
//     for (var i=0; i<content[0][pageName].length; i++) {
//       if (role == content[0][pageName][i].role) {
//         return content[0][pageName][i];
//       }
//     }
//   }
//   return {};
// }
function getInputContent(pageName, role) {
  if (content[0][pageName]) {
    for (var i=0; i<content[0][pageName].length; i++) {
      if (content[0][pageName][i].role.includes(role)) {
        return content[0][pageName][i];
      }
    }
  }
  return {};
}

export default {
  getInputContent,
  fileToBase64,
  downloadFile,
}