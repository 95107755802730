export default {
  name: "pageheader",
  components: {},
  props: ["input"],
  data() {
    return {
      openedPanel: [],
      isExpanded: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    handleToggle() {
      if (this.isExpanded) {
        this.openedPanel = [0];
      } else {
        this.openedPanel = [1];
      }
    },
    toggleClick() {
      this.isExpanded = !this.isExpanded;
      this.handleToggle();
    }
  }
};
